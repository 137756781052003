import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CarouselComponent from "./Components/Carousel/Carousel";
import MediaCard from "./Components/Card/Card";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { api_key } from "./Constants";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#202A67",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const Fleet = () => {
  
  const [vehicles, setVehicles] = useState([]);
  const { t } = useTranslation();


  const getFleet = () => {
    fetch("https://start2move.api.anyrent.pt/v1/fleets/groups?api_key=" + api_key, {
      method: "GET",
      headers:{
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setVehicles(data);
      })
  };

  useEffect(() => {
    getFleet();
 }, []);

  return (
    <>
        <ThemeProvider theme={theme}>
          <div>
            <Typography
              variant="h4"
              component="div"
              sx={{
                flexGrow: 1,
                color: "primary.main",
                textAlign: "center",
                mt: "1rem",
              }}
            >
              {t("fleet")}
            </Typography>
            {vehicles?.groups?.length && (
              <CarouselComponent>
                {vehicles.groups?.map(
                  ({ code, segment, brand, model }, index) => (
                    <>
                      {code !== "tt" && brand && (
                        <div key={index}>
                          <MediaCard
                            key={index}
                            index={index}
                            group={code}
                            brand={brand}
                            model={model}
                            seats={segment}
                          />
                        </div>
                      )}
                    </>
                  )
                )}
              </CarouselComponent>
            )}
          </div>
        </ThemeProvider>
    </>
  );
};

export default Fleet;
