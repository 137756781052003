import { Footer } from 'flowbite-react';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import Logo from '../../Assets/logo-horizontal-gradient.svg';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
function FooterComponent() {
  const { t } = useTranslation();
  return (
    <Footer container>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <img
              alt='logo'
              href="#"
              className='h-48'
              src={Logo}/>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title title={t("contacts")} />
              <Footer.LinkGroup col>
                <Footer.Link href="/#contacts">
                <EmailOutlinedIcon/> geral@start2move.pt</Footer.Link>
                <Footer.Link target="_blank" href="https://wa.me/351964411955">
                  <LocalPhoneOutlinedIcon/>+351 964411955</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title={t("informations")} />
              <Footer.LinkGroup col>
                <Footer.Link target="_blank" href="https://maps.app.goo.gl/HVeD5HaoV7fVrho17">
                  <LocationOnOutlinedIcon/> Rua do Cano Nº9-B <br/> 9100-101 Santa Cruz Madeira</Footer.Link>
                <Footer.Link href=""> <CalendarMonthOutlinedIcon/> {t("monday")} - {t("sunday")} <br/> 08h30 - 18h00</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title={t("legal")} />
              <Footer.LinkGroup col>
                <Footer.Link href="#"><PolicyOutlinedIcon/> {t("privacyPolicy")}</Footer.Link>
                <Link to="/termsAndConditions"><GavelOutlinedIcon/> {t("termsAndConditions")}</Link >
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between backgroundColor=#F4F4F4 ">
          <Footer.Copyright href="#" by="Start2Move" year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon target="_blank" href="https://facebook.com/61555918430651" icon={BsFacebook} />
            <Footer.Icon target="_blank" href="https://www.instagram.com/start2movecarrental/" icon={BsInstagram} />
          </div>
        </div>
      </div>
    </Footer>
  );
}
export default FooterComponent;