import React, {useEffect} from "react";
import "./navbar.css";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import { ReactComponent as Logo } from "../../Assets/logo-horizontal-gradient.svg";
import { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import {LANGUAGES} from '../../Constants';
import { useTranslation } from "react-i18next";
const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const select = {
    marginTop: "-10px",
    backgroundColor: "#F4F4F4"
  }

  const { i18n, t } = useTranslation();

  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  const onChangePage =()=>{
    switch(window.location.pathname){
      case '/termsAndConditions':
        setTermsAndConditions(true);
        break;
      case '/':
        setTermsAndConditions(false);
          break;
      case '/home':
        setTermsAndConditions(false);
          break;
      default:
        setTermsAndConditions(false);   
    }

    switch(window.location.hash){
      case '#aboutUs':
        setShowNavbar(false);
        break;
      case '#contacts':
        setShowNavbar(false);
          break;
      case '#fleet':
        setShowNavbar(false);
          break;
      default:
        setShowNavbar(false);   
    }
  }

  useEffect(() => {
    onChangePage();
  }, []);
  

  return (
    <nav className="navbar">
      <div className="container">
        <div>
          <Link to="/" onClick={() => onChangePage()}><Logo className="logo"/></Link> 
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <Link to="/" onClick={() => onChangePage()}>{t("home")}</Link>
            </li>
            <li>
            {termsAndConditions ? <Link to="/#fleet" onClick={() => onChangePage()} >{t("fleet")}</Link> : <Link smooth to="#fleet" onClick={() => onChangePage()}>{t("fleet")}</Link> } 
            </li>
            <li>
            {termsAndConditions ? <Link to="/#aboutUs" onClick={() => onChangePage()} >{t("about")}</Link> : <Link smooth to="#aboutUs" onClick={() => onChangePage()}>{t("about")}</Link> }
            </li>
            <li>
            {termsAndConditions ? <Link to="/#contacts" onClick={() => onChangePage()}>{t("contacts")}</Link> : <Link smooth to="#contacts"onClick={() => onChangePage()}>{t("contacts")}</Link>}
            </li>
            <li>
              <select style={select} onChange={onChangeLang} defaultValue="pt">
                {LANGUAGES.map(({ code, label }) => (
                  <option key={code} value={code}>
                    {t(label)}
                  </option>
                ))}
              </select>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
