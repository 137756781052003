import React from "react";
import Body from "./Body";
import Contacts from "./Contacts";
import "./i18n";
import About from "./About";
import Fleet from "./Fleet";

export default class App extends React.Component {
  render() {
    return (
      <>
        <Body />
        <section id="fleet">
          <Fleet />
        </section>
        <section id="aboutUs">
          <About />
        </section>
        <section id="contacts">
          <Contacts />
        </section>
      </>
    );
  }
}
