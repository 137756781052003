import React from "react";
import Carousel from "react-elastic-carousel";
import "./Carousel.css";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

function CarouselComponent({children}) {
  return (
    <>
      <div className="Carousel">
        <Carousel enableSwipe breakPoints={breakPoints}>
          {children}
        </Carousel>
      </div>
    </>
  );
}

export default CarouselComponent;
