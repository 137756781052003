import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "pt",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          home: "Home",
          contacts: "Contacts",
          about: "About",
          fleet: "Fleet",
          address: "Address",
          phone: "Phone",
          informations:"Informations",
          termsAndConditions: "Terms & Conditions",
          privacyPolicy: "Privacy Policy",
          fullName:"Full Name",
          fullNameIsRequired:"Full name is required",
          fullNameInvalid:"Full name must have at least 3 characters",
          email:"Email",
          emailIsRequired:"Email is required",
          emailInvalid:"Email is not valid",
          phoneNumber:"Phone number",
          phoneNumberIsRequired:"Phone number is required",
          phoneNumberInvalid:"Phone number must have at least 9 characters",
          message:"Message",
          messageIsRequired:"Message is required",
          messageInvalid:"Message must have at least 3 characterss",
          submit:"Send",
          monday:"Monday",
          sunday:"Sundal",
          legal:"Legal",
          Portuguese: "Portuguese", 
          Spanish: "Spanish", 
          English: "English", 
          German: "German",
          French: "French",
          emailSent:"Email sent successfully.",
          aboutTitle1:"The new car rental reference on Madeira Island",
          aboutTitle2:"A promise fulfilled",
          aboutTitle3:"More convenience, less stress",
          aboutTitle4:"Choose your measure",
          aboutText1:"Welcome to Madeira Island, where the freedom to explore each stunning corner is about to become even easier with the arrival of Start2Move, the latest car rental option in the region.Conveniently located just 5 minutes from the airport, Start2Move promises not only proximity, but an exceptional car rental experience.",
          aboutText2:"At Start2Move, we believe that freedom to explore should not compromise your pocket.This is why we commit to offering the best prices on the market.We work hard to ensure that our customers enjoy competitive tariffs, allowing them to take the most of their stay on Madeira Island.",
          aboutText3:"To make your experience even more enjoyable, Start2Move offers free airport transportation.Nothing is more comforting than knowing that, after your trip, one of our professionals will be ready to take you back to the airport, ensuring a soft transition between your arrival and departure.",
          aboutText4:"At Start2Move, we understand that each customer is unique, and their travel needs are.This is why we have a vast offer of vehicles, from ideal compact cars to explore the narrow roads of the island to spacious SUVs for group adventures.Choose the car that adapts to your travel style and enjoy your journey through Madeira.",
          addressHours:"OFFICE ADDRESS RUA DO CANO, 9-B , 9100-109 SANTA CRUZ WORKING HOURS ARE FROM 8:30AM TO 06:00PM",
          airportServiceHours:"AIRPORT SERVICE OUTSIDE OF OPENING HOURS",
          forEach:"FOR EACH",
          pickUpFrom:"PICK UP FROM",
          to06311159:"06:31pm TO 11:59pm",
          dropOffFrom:"OR DROP OFF FROM",
          tO0829:"00:00pm TO 08:29",
          AFEEOF:"A FEE OF",
          euro35:"€35,00",
          WILLBEHARGEDDIRECTLYTOTHECUSTOMER:"WILL BE CHARGED DIRECTLY TO THE CUSTOMER.",
          INTHEEVENTOFFLIGHTDELAYSCAUSINGTHISSITUATIONOUTOFHOURS:"IN THE EVENT OF FLIGHT DELAYS CAUSING THIS SITUATION, OUT OF HOURS ",
          FEEWILLAPPLY:"FEE WILL APPLY",
          TO000829am:"00:00am TO 08:29am",
          euro60:"€60,00",
          WILLBECHARGED:"WILL BE CHARGED.",
          PICKUPOUTSIDEOFOPENINGHOURSALWAYSUPONREQUEST:"PICK UP OUTSIDE OF OPENING HOURS ALWAYS UPON REQUEST.",
          orSimilar:"Or similar",
          group:"Grup",
          bookNow:"Book Now"
        },
      },
      pt: {
        translation: {
          home: "Home",
          contacts: "Contactos",
          about: "Sobre-nós",
          fleet: "Frota",
          address: "Morada",
          phone: "Telefone",
          informations:"Informações",
          termsAndConditions: "Termos e Condições",
          privacyPolicy: "Politica de Privacidade",
          fullName:"Nome Completo",
          fullNameIsRequired:"Nome é obrigatório",
          fullNameInvalid:"O nome deve ter pelo menos 3 caracteres",
          email:"Email",
          emailIsRequired:"Email é obrigatório",
          emailInvalid:"O Email não é valido",
          phoneNumber:"Número de telefone",
          phoneNumberIsRequired:"Número de telefone é obrigatório",
          phoneNumberInvalid:"O número de telefone deve ter pelo menos 9 caracteres",
          message:"Mensagem",
          messageIsRequired:"Mensagem é obrigatório",
          messageInvalid:"A mensagem deve ter pelo menos 3 caracteres",
          submit:"Enviar",
          monday:"Segunda-Feira",
          sunday:"Domingo",
          legal:"Legal",
          Portuguese: "Portugues", 
          Spanish: "Espanhol", 
          English: "Inglês", 
          German: "Alemão",
          French: "Francês",
          emailSent:"Email enviado com sucesso.",
          aboutTitle1:"A Nova Referência em Aluguer de Carros na Ilha da Madeira",
          aboutTitle2:"Uma Promessa Cumprida",
          aboutTitle3:"Mais Conveniência, Menos Stress",
          aboutTitle4:"Escolha à Sua Medida",
          aboutText1:"Seja bem-vindo à ilha da Madeira, onde a liberdade de explorar cada recanto deslumbrante está prestes a tornar-se ainda mais fácil com a chegada da Start2Move, a mais recente opção de aluguer de carros na região. Convenientemente localizada a apenas 5 minutos do aeroporto, a Start2Move promete não apenas proximidade, mas uma experiência excepcional de aluguer de carros.",
          aboutText2:"Na Start2Move, acreditamos que a liberdade de explorar não deve comprometer o seu bolso. É por isso que nos comprometemos a oferecer os melhores preços do mercado. Trabalhamos arduamente para garantir que os nossos clientes desfrutem de tarifas competitivas, permitindo-lhes tirar o máximo proveito da sua estadia na ilha da Madeira.",
          aboutText3:"Para tornar a sua experiência ainda mais agradável, a Start2Move oferece transporte gratuito para o aeroporto. Nada é mais reconfortante do que saber que, após a sua viagem, um dos nossos profissionais estará pronto para o levar de volta ao aeroporto, garantindo uma transição suave entre a sua chegada e partida.",
          aboutText4:"Na Start2Move, entendemos que cada cliente é único, e as suas necessidades de viagem também o são. É por isso que dispomos de uma vasta oferta de viaturas, desde carros compactos ideais para explorar as estreitas estradas da ilha até SUVs espaçosos para aventuras em grupo. Escolha a viatura que se adapta ao seu estilo de viagem e desfrute da sua jornada pela Madeira.",
          orSimilar:"Ou similar",
          group:"Grupo",
          bookNow:"Reserve"
        },
      },
      es: {
        translation: {
          home: "Casa",
          contacts: "Contactos",
          about: "Acerca de",
          fleet: "Flota",
          address: "Dirección",
          phone: "Teléfono",
          informations:"Informaciones",
          termsAndConditions: "Términos y Condiciones",
          privacyPolicy: "Política de privacidad",
          fullName:"Nombre completo",
          fullNameIsRequired:"El nombre es obligatorio",
          fullNameInvalid:"El nombre debe tener al menos 3 caracteres.",
          email:"Email",
          emailIsRequired:"El correo electrónico es obligatorio",
          emailInvalid:"El correo electrónico no es válido.",
          phoneNumber:"Número de teléfono",
          phoneNumberIsRequired:"Se requiere número de teléfono",
          phoneNumberInvalid:"El número de teléfono debe tener al menos 9 caracteres.",
          message:"Mensaje",
          messageIsRequired:"El mensaje es obligatorio",
          messageInvalid:"El mensaje debe tener al menos 3 caracteres.",
          submit:"Mandar",
          monday:"Lunes",
          sunday:"Domingo",
          legal:"Fresco",
          Portuguese: "portugués", 
          Spanish: "Español", 
          English: "Inglés", 
          German: "Alemán",
          French: "Francés",
          emailSent:"Correo electrónico enviado correctamente.",
          aboutTitle1:"La nueva referencia de alquiler de automóviles en la isla Madeira",
          aboutTitle2:"Una promesa cumplida",
          aboutTitle3:"Más conveniencia, menos estrés",
          aboutTitle4:"Elige tu medida",
          aboutText1: "Bienvenido a la isla Madeira, donde la libertad de explorar cada esquina impresionante está a punto de ser aún más fácil con la llegada de Start2Move, la última opción de alquiler de automóviles en la región. Cecti y a solo 5 minutos del aeropuerto, Start2Move promete no soloproximidad, pero una experiencia de alquiler de automóvil excepcional ",
          aboutText2: "En Start2Move, creemos que la libertad de explorar no debe comprometer su bolsillo. Por eso nos comprometemos a ofrecer los mejores precios en el mercado. Trabajamos duro para asegurarnos de que nuestros clientes disfruten de aranceles competitivos, lo que les permite tomar la mayoríade su estadía en la isla de Madeira ",
          aboutText3: "Para hacer que su experiencia sea aún más agradable, Start2Move ofrece transporte gratuito del aeropuerto. No es más reconfortante que saber que, después de su viaje, uno de nuestros profesionales estará listo para llevarlo de regreso al aeropuerto, asegurando una transición suave entre sullegada e ida.",
          aboutText4: "En Start2Move, entendemos que cada cliente es único, y sus necesidades de viaje lo son. Esta es la razón por la cual tenemos una gran oferta de vehículos, desde autos compactos ideales para explorar las carreteras estrechas de la isla hasta espaciosos SUV para aventuras grupales.Elija el automóvil que se adapte a su estilo de viaje y disfrute de su viaje a través de Madeira ",
          orSimilar:"Ou similar",
          group:"Grupo",
          bookNow:"Reservar"
        },  
      },
      de: {
        translation: {
          home: "Home",
          contacts: "Contactos",
          about: "Sobre-nós",
          fleet: "Frota",
          address: "Morada",
          phone: "Telefone",
          informations:"Informações",
          termsAndConditions: "Termos e Condições",
          privacyPolicy: "Politica de Privacidade",
          fullName:"Nome Completo",
          fullNameIsRequired:"Nome é obrigatório",
          fullNameInvalid:"O nome deve ter pelo menos 3 caracteres",
          email:"Email",
          emailIsRequired:"email é obrigatório",
          emailInvalid:"O email não é valido",
          phoneNumber:"Número de telefone",
          phoneNumberIsRequired:"número de telefone é obrigatório",
          phoneNumberInvalid:"O número de telefone deve ter pelo menos 9 caracteres",
          message:"Mensagem",
          messageIsRequired:"Mensagem é obrigatório",
          messageInvalid:"A mensagem deve ter pelo menos 3 caracteres",
          submit:"Enviar",
          monday:"Segunda-Feira",
          sunday:"Domingo",
          legal:"Legal",
          Portuguese: "Portugues", 
          Spanish: "Espanhol", 
          English: "Inglês", 
          German: "Alemão",
          French: "Francês",
        },
      },
      fr: {
        translation: {
          home: "Home",
          contacts: "Contactos",
          about: "Sobre-nós",
          fleet: "Frota",
          address: "Morada",
          phone: "Telefone",
          informations:"Informações",
          termsAndConditions: "Termos e Condições",
          privacyPolicy: "Politica de Privacidade",
          fullName:"Nome Completo",
          fullNameIsRequired:"Nome é obrigatório",
          fullNameInvalid:"O nome deve ter pelo menos 3 caracteres",
          email:"Email",
          emailIsRequired:"email é obrigatório",
          emailInvalid:"O email não é valido",
          phoneNumber:"Número de telefone",
          phoneNumberIsRequired:"número de telefone é obrigatório",
          phoneNumberInvalid:"O número de telefone deve ter pelo menos 9 caracteres",
          message:"Mensagem",
          messageIsRequired:"Mensagem é obrigatório",
          messageInvalid:"A mensagem deve ter pelo menos 3 caracteres",
          submit:"Enviar",
          monday:"Segunda-Feira",
          sunday:"Domingo",
          legal:"Legal",
          Portuguese: "Portugues", 
          Spanish: "Espanhol", 
          English: "Inglês", 
          German: "Alemão",
          French: "Francês",
        },
      },
    },
  });

export default i18n;