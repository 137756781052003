import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Carousel } from "flowbite-react";
import useWindowSize from "./useWindowSize";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const About = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  return (
    <ThemeProvider theme={theme}>
        <Typography
          variant="h4"
          component="div"
          sx={{ color: "primary.main", textAlign: "center", mt: "1rem" }}
        >
          {t("about")}
        </Typography>
        {size.width > 650 ? (
      <div className="h-20 ml-20 mr-20 sm:h-40 xl:h-40 2xl:h-40">
          <Carousel pauseOnHover>
            <Typography
              variant="h5"
              sx={{
                color: "secondary.main",
                textAlign: "center",
                ml: "1rem",
                mt: "1rem",
              }}
            >
              {t("aboutTitle1")}
            </Typography>
            <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
              Seja bem-vindo à ilha da Madeira, onde a liberdade de explorar
              cada recanto deslumbrante está prestes a tornar-se ainda mais
              fácil com a chegada da Start2Move, a mais recente opção de aluguer
              de carros na região. Convenientemente localizada a apenas 5
              minutos do aeroporto, a Start2Move promete não apenas proximidade,
              mas uma experiência excepcional de aluguer de carros.
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: "secondary.main",
                textAlign: "center",
                ml: "1rem",
                mt: "1rem",
              }}
            >
              {t("aboutTitle2")}
            </Typography>
            <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
              Na Start2Move, acreditamos que a liberdade de explorar não deve
              comprometer o seu bolso. É por isso que nos comprometemos a
              oferecer os melhores preços do mercado. Trabalhamos arduamente
              para garantir que os nossos clientes desfrutem de tarifas
              competitivas, permitindo-lhes tirar o máximo proveito da sua
              estadia na ilha da Madeira.
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: "secondary.main",
                textAlign: "center",
                ml: "1rem",
                mt: "1rem",
              }}
            >
              {t("aboutTitle3")}
            </Typography>
            <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
              Para tornar a sua experiência ainda mais agradável, a Start2Move
              oferece transporte gratuito para o aeroporto. Nada é mais
              reconfortante do que saber que, após a sua viagem, um dos nossos
              profissionais estará pronto para o levar de volta ao aeroporto,
              garantindo uma transição suave entre a sua chegada e partida.
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: "secondary.main",
                textAlign: "center",
                ml: "1rem",
                mt: "1rem",
              }}
            >
              {t("aboutTitle4")}
            </Typography>
            <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
              Na Start2Move, entendemos que cada cliente é único, e as suas
              necessidades de viagem também o são. É por isso que dispomos de
              uma vasta oferta de viaturas, desde carros compactos ideais para
              explorar as estreitas estradas da ilha até SUVs espaçosos para
              aventuras em grupo. Escolha a viatura que se adapta ao seu estilo
              de viagem e desfrute da sua jornada pela Madeira.
            </Typography>
          </Carousel>
          </div>
        ) : (
          <Container>
            {" "}
            <Typography
              variant="h5"
              sx={{
                color: "secondary.main",
                textAlign: "center",
                ml: "1rem",
                mt: "1rem",
              }}
            >
              {t("aboutTitle1")}
            </Typography>
            <Typography className="ml-10 mr-10 p-4 dark:border-gray-500 dark:bg-gray-800">
              Seja bem-vindo à ilha da Madeira, onde a liberdade de explorar
              cada recanto deslumbrante está prestes a tornar-se ainda mais
              fácil com a chegada da Start2Move, a mais recente opção de aluguer
              de carros na região. Convenientemente localizada a apenas 5
              minutos do aeroporto, a Start2Move promete não apenas proximidade,
              mas uma experiência excepcional de aluguer de carros.
            </Typography>
          </Container>
        )}
    </ThemeProvider>
  );
};

export default About;
