import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import P208 from "../../Assets/peugeot208.png";
import P108 from "../../Assets/peugeot-108.png";
import OCROSSLAND from "../../Assets/crossland.webp";
import OINSIGNIA from "../../Assets/insignia.png";
import TROC from "../../Assets/troc.png";
import TOURAN from "../../Assets/touran.png";
import P5008 from "../../Assets/P5008.png";
import TRAVELLER from "../../Assets/traffic.jpg";
import CORSA from "../../Assets/corsa.webp";
import { Button, CardActions } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function MediaCard({ index, group, brand, model, seats }) {
  const { t } = useTranslation();
  return (
    <>
  <Card sx={{ maxWidth: 600 }} key={index}>
          <Typography
            gutterBottom
            variant="h5"
            align="center"
            color="primary.main"
            component="div"
          >
            {t("group")} {group}
          </Typography>
          <Typography gutterBottom variant="h6" align="center" component="div">
            {brand} {model} {t("orSimilar")}
          </Typography>
          {group === "A" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={P108}
              title="CAR"
            />
          )}
          {group === "C" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={CORSA}
              title="CAR"
            />
          )}
          {group === "D" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={P208}
              title="CAR"
            />
          )}
          {group === "J" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={CORSA}
              title="CAR"
            />
          )}
          {group === "L" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={OCROSSLAND}
              title="CAR"
            />
          )}
          {group === "K" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={OINSIGNIA}
              title="CAR"
            />
          )}
          {group === "K1" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={TROC}
              title="CAR"
            />
          )}
          {group === "M" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={TOURAN}
              title="CAR"
            />
          )}
          {group === "M1" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={P5008}
              title="CAR"
            />
          )}
          {group === "F" && (
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={TRAVELLER}
              title="CAR"
            />
          )} 
          <CardActions>
          <Button variant="contained" size="small">{t("bookNow")}</Button>
        </CardActions>
        </Card>
          </>
  );
}
