import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import background from "./Assets/background.png"

const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#202A67",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});



const Body = () => {
  return (
    <ThemeProvider theme={theme}>

      <div
        style={{
          backgroundImage: `url(${background})`,
          height: "500px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
      </div>
    </ThemeProvider>
  );
};

export default Body;
