import React, {useEffect} from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { List } from "flowbite-react";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1C2541",
    },
    secondary: {
      main: "#38AECC",
    },
    terceary: {
      main: "#F4F4F4",
    },
  },
});

const TermsAndConditions = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const { t } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography
          variant="h3"
          component="div"
          sx={{
            flexGrow: 1,
            color: "primary.main",
            textAlign: "center",
            mt: "1rem",
          }}
        >
          {t("termsAndConditions")}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{textAlign: "center", float:"center" , mt:"1rem"}}
        >
          <b>OFFICE ADDRESS RUA DO CANO, 9-B , 9100-109 SANTA CRUZ WORKING HOURS ARE FROM 8:30AM TO 06:00PM</b>
        </Typography>
        </Container>
        <Container sx={{
            mt:"1rem",
          }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            color: "primary.main",
            textAlign: "center",
          }}
        >
          AIRPORT SERVICE OUTSIDE OF OPENING HOURS
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: "justify",
            mt: "1rem",
            mr: "5rem",
            ml: "5rem",
          }}
        >
          FOR EACH PICK UP FROM <b>06:31pm TO 11:59pm</b> OR DROP OFF FROM <b>00:00pm TO
          08:29</b> A FEE OF <b style={{ color: "blue" }}>€35,00</b> WILL BE CHARGED DIRECTLY TO THE CUSTOMER.
          {<br />} IN THE EVENT OF FLIGHT DELAYS CAUSING THIS SITUATION, OUT OF
          HOURS <b>FEE WILL APPLY</b>.
          {<br />}
          PICK UP FROM <b>00:00am TO 08:29am</b> A FEE OF <b style={{ color: "blue" }}>€ 60,00</b> WILL BE CHARGED.
          {<br />}
          PICK UP OUTSIDE OF OPENING HOURS ALWAYS UPON REQUEST.
        </Typography>
        </Container>
        <Container sx={{
            mt:"1rem",
          }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            color: "primary.main",
            textAlign: "center",
          }}
        >
          EXTRA FEES
        </Typography>
          <List>
            <List.Item>
              <b>BABY SEATS ISOFIX:</b> MAY ALSO BE SUPPLIED ON REQUEST. THE
              COST FOR THESE WILL BE <b style={{ color: "blue" }}> € 10,00</b> PER DAY ON A MAXIMUM OF <b style={{ color: "blue" }}>€ 90,00</b> PER
              RENTAL.
            </List.Item>
            <List.Item>
              <b>BABY SEATS:</b> MAY ALSO BE SUPPLIED ON REQUEST. THE COST FOR
              THESE WILL BE <b style={{ color: "blue" }}>€ 5,00</b> PER DAY ON A MAXIMUM OF  <b style={{ color: "blue" }}>€ 60,00</b> PER RENTAL.
            </List.Item>
            <List.Item>
              <b>BOOSTER SEAT:</b> MAY ALSO BE SUPPLIED ON REQUEST. THE COST FOR
              THESE WILL BE  <b style={{ color: "blue" }}>€ 2,00</b> PER DAY ON A MAXIMUM OF  <b style={{ color: "blue" }}>€ 28,00</b> PER RENTAL.
            </List.Item>
            <List.Item>
              <b>YOUNG DRIVERS:</b> ALL DRIVERS BETWEEN 21 AND 25 YEARS OLD MUST
              PAY DIRECTLY TO WHY NOT AN ADDITIONAL TAX OF <b style={{ color: "blue" }}>€ 35,00</b> PER RENTAL.
            </List.Item>
            <List.Item>
              <b>ADDITIONAL DRIVER:</b> SECOND DRIVER INCLUDED. FOR EACH
              ADDITIONAL DRIVER MENTIONED ON THE RENTAL AGREEMENT A FEE OF
              <b style={{ color: "blue" }}>€20,00</b> WILL BE CHARGED.
            </List.Item>
            <List.Item>
              <b>FERRY PORTO SANTO:</b> MAY ALSO BE AUTHORISED ON REQUEST WITH A
              COST OF <b style={{ color: "blue" }}>€ 60,00</b> PER RENTAL. ONE WAY IS NOT ALLOWED.
            </List.Item>
          </List>
        </Container>
        
        <Container>
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            color: "primary.main",
            textAlign: "center",
            mt: "1rem",
          }}
        >
          <i>
            To be covered by Insurance, Drivers must be duly mentioned on the
            Rental Agreement.
          </i>
        </Typography>
        <Container sx={{ mt: "1rem" }}>
          <List>
            <List.Item>
              <b>COLLECTING IN ADVANCE:</b> THROUGH THIS RATE WE SHALL NOT
              REFUND EITHER THE CLIENT NOR THE AGENCY INVOLVED IN THE BOOKING
              FOR THE TOTAL OR PARCIAL RENTAL DAYS WHICH WERE NOT USED.
            </List.Item>
            <List.Item>
              <b>NO SHOW / NON-COMPLIANCE WITH THE AGREED TERMS:</b> ALL BOOKED
              RENTAL DAYS WILL BE INVOICED IN CASE OF NO SHOW OR IF CLIENT DOES
              NOT MEET RENTAL REQUIREMENTS.
            </List.Item>
            <List.Item>
              <b>BOOKING ALTERATION:</b> FOR ANY ALTERATION 48H PRIOR TO CAR
              DELIVERY, A FEE OF <b style={{ color: "blue" }}> € 30,00</b> WILL BE CHARGED DIRECTLY TO THE CLIENT.
            </List.Item>
          </List>
          </Container>
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            color: "primary.main",
            textAlign: "center",
            mt: "1rem",
          }}
        >
          <i>Very Important</i>
        </Typography>

        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          THE CLIENT (WHETHER DRIVER OR NOT) IS ALWAYS RESPONSIBLE FOR AN EXCESS
          ON THE AMOUNT OF <b style={{ color: "blue" }}>€ 1.000,00</b> FOR{" "}
          <b>GROUP A</b>, <b style={{ color: "blue" }}>€ 1.500,00</b> FOR{" "}
          <b>GROUPS C, D and J </b>
          <b style={{ color: "blue" }}>€ 2.000,00</b> FOR{" "}
          <b>GROUPS K, K1 and L</b>, <b style={{ color: "blue" }}>€ 3.000,00</b>{" "}
          FOR <b>GROUPS F, M and M1</b> IN CASE OF A SELF-PROVOKED ACCIDENT, IN
          CASE OF DAMAGES CAUSED BY ACTS OF VANDALISM, IN CASE THEFT OF THE
          VEHICLE OR OF THEFT OF ANY OF ITS ACCESSORIES, AS LONG AS THE DRIVER
          IS UNABLE TO INDENTIFY THE GUILTY PARTY.
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          THIS SECURITY DEPOSIT MUST BE MADE UPON THE DELIVERY OF THE CAR BY
          CREDIT CARD.
        </Typography>
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          THE REFERRED SECURITY DEPOSIT WILL BE REFUNDED TO THE CLIENT AT THE
          END OF THE RENTAL, IN CASE <b>NO</b> ACCIDENT HAS TAKEN PLACE, AS WELL
          AS NO DAMAGES HAVE BEEN CAUSED TO THE VEHICLE BY ACTS OF VANDALISM,
          THEFT OF THE VEHICLE OR OF ANY OF ITS ACCESSORIES.
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          THIS SITUATION CAN ONLY BE ELIMINATED WITH THE PAYMENT OF{" "}
          <b>SUPER C.D.W.</b>
          OF <b style={{ color: "blue" }}>€ 10,00 </b>PER DAY FOR{" "}
          <b>GROUPS A</b>, <b style={{ color: "blue" }}>€ 15,00</b> PER DAY FOR{" "}
          <b>GROUPS C, D and J</b>, <b style={{ color: "blue" }}>€ 20,00</b> FOR{" "}
          <b>GROUPS K, K1 and L</b>, <b style={{ color: "blue" }}>€ 30,00</b>{" "}
          FOR <b>GROUPS F, M and M1</b> THIS AMOUNT HAS TO BE PAID BY THE CLIENT
          WHEN RECEIVING THE VEHICLE ALWAYS ON{" "}
          <b style={{ color: "red" }}>A MINIMUM CHARGE BASIS OF 3 DAYS</b>.
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          THE ABOVE COVER DOES NOT ELIMINATE THE DAMAGES OR ACTS OF VANDALISM
          MENTIONED BELLOW: CLIENTS WILL BE HELD RESPONSIBLE FOR DAMAGES
          INFLICTED TO TIRES, GLASSES, WHEEL RIMS, EXTERIOR MIRRORS,
          UNDERCARRIAGE AND ACTS OF VANDALISM BY UNKNOWN OR NOT. THIS
          RESPONSIBILITY CAN BE ELIMINATED BY THE EXTRA COVERAGE FEE OF
          <b style={{ color: "blue" }}> € 5,00</b>
          PER DAY FOR <b>GROUPS A, C, D and J</b>,{" "}
          <b style={{ color: "blue" }}>€ 10,00</b> PER DAY FOR{" "}
          <b>GROUPS FOR GROUPS K, K1 and L</b>,{" "}
          <b style={{ color: "blue" }}>€ 15,00</b> FOR <b>GROUPS F, M and M1</b>{" "}
          TO BE PAID BY THE CLIENT DIRECTLY TO WHY NOT CAR RENTAL ON{" "}
          <b style={{ color: "red" }}> A MINIMUM CHARGE BASIS OF 3 DAYS.</b>
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          <b>EXCLUSIONS:</b> NEGLIGENT USE / TRAFIC VIOLATIONS AS PARKING FINES,
          KEYS LOSS, FUEL ERROR, VEHICULE INTERIOR, CLUTCH AND GEAR BOX.
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          IN CASE OF ACCIDENT BY OUR CAR DRIVER'S FAULT TAKING INTO
          CONSIDERATION PORTUGUESE LAW, INSURANCE COMPANIES DECLINE ANY OR ALL
          RESPONSIBILITY FOR THAT DRIVER OF ALL DAMAGES CAUSED TO THE HIRED
          VEHICLE OR TO THIRD PARTIES WHENEVER IT IS DETECTED THAT THE DRIVER
          WAS DRIVING UNDER THE INFLUENCE OF ALCOHOL, NARCOTICS, DRUGS OR TOXIC
          PRODUCTS OR BY INSANITY OF THE DRIVER. ALSO IF THE CLIENT USES THE CAR
          IN SPORTIVE COMPETITIONS, RACES, RALLYS OR CHALLENGES, CONTESTS, BETS,
          ETC.
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem" }}
        >
          WE ALSO TAKE THIS OPPORTUNITY TO INFORM THAT EVEN IF THE CAR'S DRIVER
          IS NOT UNDER THE INFLUENCE OF ANY OF THE ABOVE ITEMS BUT BY ANY MEANS
          ALSO DESTROYS OUR CAR BY HIS OWN FAULT WE SHALL NOT REFUND ANY AMOUNT
          FOR THE TIME LEFT TO COMPLETE THE RENTAL AND WE ONLY GIVE ANOTHER CAR
          THROUGH A LARGE DEPOSIT THAT MAY COVER EVENTUAL DAMAGES THAT CAN BE
          CAUSED TO THE NEW CAR. WE WOULD LIKE CLIENTS TO BE WELL INFORMED ABOUT
          THE ABOVE SITUATION.
        </Typography>
        
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "justify", mt: "1rem", mb:"1rem" }}
        >
          ALL BOOKINGS ARRIVING AT OUR HEAD OFFICE-BOOKING DEPARTMENT WITH LESS
          THAN 3 DAYS TO DELIVERY TIME WILL BE CONFIRMED ON REQUEST.
        </Typography>
        </Container>
    </ThemeProvider>
  );
};

export default TermsAndConditions;
